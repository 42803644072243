import React from 'react';
import logo from '../FabBoxLogo.svg';

const ContentContainer: React.FC = () => {
  return (
    <div className="flex relative flex-col justify-center px-4 py-11 max-w-full bg-white bg-opacity-20 rounded-[50px] w-[618px]">
      <img
        loading="lazy"
        src={logo}
        alt="FabBox Logo"
        className="object-contain w-full aspect-[4.37] rounded-[50px] max-md:max-w-full"
      />
    </div>
  );
};

export default ContentContainer;