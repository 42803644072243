import React from 'react';
import { createClient } from '@supabase/supabase-js'

const supabase = createClient('https://quknzqwgtimhawwonqur.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF1a256cXdndGltaGF3d29ucXVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUxMjQyNzAsImV4cCI6MjA0MDcwMDI3MH0.RJeuqb6533O8iBxE0esIDHj28iEcRyoA5Nk5AwVSViw')

const NewsletterForm: React.FC = () => {
  
  const [subscribed, setSubscribed] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    const email = e.currentTarget.emailInput.value;
    const { data, error } = supabase.functions.invoke('newsletter', { body: JSON.stringify({ "email": email }) });

    if (error) {
      console.error(error);
      return `Error: ${error.message}`;
    }
    console.log(data);
    setSubscribed(true);
  };

  return (
    <>
    {!subscribed ? (
      <form className="flex flex-wrap gap-10 self-center px-8 py-3.5 mt-10 max-w-full text-base leading-none bg-white bg-opacity-20 rounded-[50px] w-[690px] max-md:px-5 max-md:mt-10" onSubmit={handleSubmit}>
        <div className="flex flex-col grow shrink-0 whitespace-nowrap basis-0 text-[#333333] w-fit">
          <label htmlFor="emailInput" className="sr-only">
            Email
          </label>
          <input
            id="emailInput"
            type="email"
            placeholder="Email"
            aria-label="Email"
            className="overflow-hidden flex-1 shrink self-stretch px-4 py-3 w-full bg-white rounded-lg border border-solid border-[#333333] min-w-[240px]"
          />
        </div>
        <button
          type="submit"
          className="overflow-hidden gap-2 p-4 self-stretch p-3 rounded-lg border border-solid bg-[#333333] border-[#333333] text-neutral-100 max-sm:mx-auto"
        >
          Subscribe to our Newsletter
        </button>
      </form>) : 
      (
      <h2 className="text-2xl text-center text-black">Subscribed successfully</h2>
      )}
    </>
  );
};

export default NewsletterForm;