import React from "react";
import ImageGenerationHero from "./components/ImageGenerationHero";


function App() {
  return (
    <div>
      <ImageGenerationHero />
    </div>
  );
}

export default App;
